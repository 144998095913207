<template>
  <div>
    <div id="content">
      <div style="font-size:25px;font-weight: 800;">更新日志</div>
      <div style="margin-top: 50px;">
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp">
            <div class="vers">{{ activity.version }}</div>
            <ul>
              <li class="list" v-for="(content, index) in activity.list" :key="index">{{ content }}</li>

            </ul>

          </el-timeline-item>
        </el-timeline>
      </div>





    </div>
  </div>
</template>
<script>
export default {

  name: 'ChangeLog',
  props: {
    type: String
  },
  data() {
    return {
      reverse: true,
      activities: [
        {
          version: '2.0.1',
          list: [
            "晨星增加3个字段，项目编号，城市名，客户名",
            "修复一些系统细节问题"

          ],
          timestamp: '2023-08-21'
        }, {
          version: '2.0.2',
          list: [
            "dc模块中oe部分关闭“前三后二”检测",
            "oe错别字改到dcquota定义",
            "修复弹框滑动消失问题",
            "新增项目列表刷新按钮",
          ],
          timestamp: '2023-08-23'
        },
        {
          version: '2.0.3',
          list: [
            "新增错别字独立模块",
            "screenout新增multi均为空时的判断",
          ],
          timestamp: '2023-08-29'
        },
        {
          version: '2.1.0',
          list: [
            "新增info-logic模块",
            "新增版本更新日志",
          ],
          timestamp: '2023-09-26'
        },
        {
          version: '2.1.1',
          list: [
            "info-logic模块,numericlist输入框支持数字范围，如 3-10",
            "修改loop识别逻辑，只通过前端标注位来识别，忽略level_id",
          ],
          timestamp: '2023-09-26'
        },
        {
          version: '2.1.2',
          list: [
            "生成模板里面展示变量，由loop号为1的变量，改为datamap里面第一个变量",
          ],
          timestamp: '2023-09-28'
        },
        {
          version: '2.2.0',
          list: [
            "新增info-pv模块",
            "新增info-scene模块",
            "新增宏模块",
          ],
          timestamp: '2023-10-09'
        },
        {
          version: '2.2.1',
          list: [
            "修复info-scene的表头跟下方内容不对齐的问题",
            "修复info-scene针对部分需要忽略的p值仍写入scene的问题",
            "增加项目列表页面页码记录功能，实现按钮时可以回到前面的页码",
            "info页面增加返回按钮",
          ],
          timestamp: '2023-10-10'
        },
        {
          version: '2.2.2',
          list: [
            "修改info-scene部分p值计算默认范围值为空",
            "修复oldtonew p值100%时被转换的问题",
            "info-scene增加表侧变量的多选功能",
          ],
          timestamp: '2023-10-12'
        },
        {
          version: '2.3.0',
          list: [
            "新增logic-web模块",
          ],
          timestamp: '2023-10-12'
        },
        {
          version: '2.4.0',
          list: [
            "新增Instruct指令模块",
            "info-logic新生成表达式修改直接入库",
            "logic入库增加去重功能",
            "增加info-scene贴入datamap时的scene、p列检测删除功能",
          ],
          timestamp: '2023-10-21'
        },
        {
          version: '2.4.1',
          list: [
            "Instruct指令模块新增openexclusion、match、follow、max、min、atleastone、samepartrevise、wordwithbrand、rank",
            "score修改题型判断规则，排除AnswerCode大于11的记录",
            "openpic排除Variable ID包含other的记录",
            "openpick品牌号提取规则修改为,通过右边往左第二第三位的99分隔，其左侧的部分即为品牌号",
            "更新rank的档位信息，10分制增加1-11的AnswerCode类型",
          ],
          timestamp: '2023-10-27'
        },
        {
          version: '2.4.2',
          list: [            
            "wordwithbrand增加非loop情况的处理规则",
            "增加对key和values变量不同为loop情况的处理，处理逻辑为：为loop的变量去循环匹配非loop变量(key和values变量都有可能为非loop）",            
            "增加对key和values变量不同为loop情况的处理",
            "修复Instruct指令模块已知的其他问题",           
            "info-logic 针对multi的key改成!=1(现在是==0)",
            "dc模块读取logic时，增加中文字符感叹号、双引号、逗号 转换为英文格式的功能"
          ],
          timestamp: '2023-10-27'
        },
        {
          version: '2.5.0',
          list: [            
            "增加kpi-web模块",            
          ],
          timestamp: '2023-11-22'
        },
        {
          version: '2.5.1',
          list: [            
            "kpi-web模块grid类型的处理逻辑",            
            "kpi-web模块增加计算格式、统计单位、excel复制功能、宏调取",
          ],
          timestamp: '2023-11-27'
        },
        {
          version: '2.5.2',
          list: [            
            "kpi-web模块增加edit功能",            
            "kpi报告table展示顺序按照datamap顺序排列",
            "kpi表头表侧修复全选时没有去重的问题",
            "kpi表头表侧修改右侧文案‘不选’为‘不等于’",
            "由项目列表进入的内页，顶部增加项目编号和项目名称的显示",
          ],
          timestamp: '2023-12-26'
        },
        {
          version: '2.5.3',
          list: [            
            "更新info-pv表侧多选后端处理逻辑",                        
          ],
          timestamp: '2023-12-18'
        },
        {
          version: '2.5.4',
          list: [            
            "kpi报表展示页增加表头表侧冻结样式",                        
            "kpi报表展示页调整同题内loop排序",
            "kpi报表展示页分表头去掉p和pGap列",
            "优化部分ui样式"
          ],
          timestamp: '2023-12-20'
        },
        {
          version: '2.5.5',
          list: [            
            "kpi报表支持表头为空的情况",
            "kpi报表增加table底部‘总计’字段", 
            "oldtonew模块修改multi-loop计算、字段匹配、p值缩放规则",   
            "优化info、kpi内页加载方式",
            "kpi模块增加后端缓存，提高刷新时响应速度",
            "修改kpi报告自动加载模式为手动加载",

          ],
          timestamp: '2023-12-25'
        },
        {
          version: '2.5.6',
          list: [                        
            "增加kpi报告下载",
            "重构kpi-edit功能，支持表头表侧多选",   
          ],
          timestamp: '2023-12-28'
        },
        {
          version: '2.6.0',
          list: [                        
            "增加pv-web模块",            
          ],
          timestamp: '2023-12-30'
        },
        {
          version: '2.6.1',
          list: [          
            "增加kpi-edit执行记录",              
            "增加tagtoscene功能，替换原项目数据处理",            
            "pv-web模块优化前端ui，增加数据复制和筛选功能"
          ],
          timestamp: '2024-01-03'
        },
        {
          version: '2.7.0',
          list: [          
            "增加kpi-open模块",              
            "增加系统消息模块",            
          ],
          timestamp: '2024-01-10'
        },
        {
          version: '2.7.1',
          list: [   
          "增加kpi报告复制和删除功能",            
            "增加项目一键导出功能",       
            "kpi-open更新时间，由‘时间’改为‘日期和时间’",              
            "kpi报告QuestionLabel中的'^'内容替换",
           
          ],
          timestamp: '2024-01-14'
        },
        {
          version: '2.7.2',
          list: [   
          "修复因数据库字段长度问题导致的numericlist被截段问题",  
          "项目数据导出，只有管理员有权限导出",
          "tagtoscene模块，增加AnswerCode空值判断(跳过，针对不明原因导致的logic中AnswerCode为空的情况)",
          "reportal增加重命名功能",
          "Kpi生成报告后，改为直接更新数据，不需要在点击展示报告",
          "表头表侧页面中的“全选”选中的对象，修改为搜索筛选出来的变量",
          "调整scene、super与p值(范围：1-5)的对应关系,增加scene p判断(跳过,针对scene p为空的情况)"
          ],
          timestamp: '2024-01-22'
        },
        {
          version: '2.8.0',
          list: [   
          "增加项目数据内，'其他数据'管理,并且优化了服务端数据存储逻辑",            
          "增加子项目功能",
          "增加反算模块(base不支持组合表达式)",
          "增加新版oldtonew模块,支持自动匹配,手动匹配,scene和logic转移",
          "info-logic模块增加对同题选择的支持",
          "增加插入scene时的判断和提示",
          "宏创建增加多列以及'宏or'的支持",
          "kpi模块增加对计算格式的独立设置",
      
          ],
          timestamp: '2024-03-28'
        }, 
        {
          version: '2.8.1',
          list: [   
          "项目管理页面新增搜索功能",            
          "反算和oldtonew模块项目选择增加搜索功能",      
          "增加kpi组合表功能",
          "修复kpi-edit生成multi表达式错误问题"          
          ],
          timestamp: '2024-04-01'
        },
        {
          version: '2.8.2',
          list: [   
            "撞数和kpi计算模块,增加了对括号的支持",
          "更新kpi开放浏览模块",            
          "更新撞数模块",      
          "更新kpi-nps计算逻辑",          
          "调整kpi-pv模块,增加scene隐藏功能,取消对opentext题型的过滤"         

          ],
          timestamp: '2024-04-13'
        },
        {
          version: '2.9.0',
          list: [   
            "增加撞数表模块",
            "修复撞数模块rawdata读取时问题",      
          "修复开放浏览模块,开放数量计算问题"                          
          ],
          timestamp: '2024-04-18'
        },
        {
          version: '2.9.1',
          list: [   
            "增加配额表模块对无标头情况的支持",
            "kpi模块修改为刷新页面后留在当前标签页",      
            "开放浏览模块增加预览功能，并调整执行逻辑",
            "kpi计算增加ranking类型的支持",
            "解决因数据库字段长度问题导致的kpi部分展现异常"

          ],
          timestamp: '2024-04-25'
        },{
          version: '2.9.2',
          list: [   
            "增加项目创建时的默认编号,避免因无项目编号导致的样式问题",
            "修复创建项目时，所属成员无法保存的问题",
            "修复kpi模块in中空格问题（该问题导致了nps计算错误）",
            "修复kpi报表QuestionLabel显示问题",
            "增加kpi计算中，rawdata中变量是否存在的验证",
            "修复开放浏览起止时间为空时的识别问题",
            "修改info模块模板排序为按照前端添加顺序",     
            "增加datamap生成宏时排序处理，按照从左到右，从上到下排序",
            "增加Logic出示逻辑中的同题选项处理和表达式导入功能",
            "修复info-logic中numericlist类型生成异常问题"
          ],
          timestamp: '2024-05-03'
        },{
          version: '2.10.0',
          list: [   
            "增加kpi关联表功能",           
          ],
          timestamp: '2024-05-09'
        },{
          version: '2.10.1',
          list: [   
            "oldtonew模块自动匹配字段增加type",           
          ],
          timestamp: '2024-05-17'
        },{
          version: '2.10.2',
          list: [   
            "oldtonew模块增加QuestionLabel字符替换",           
          ],
          timestamp: '2024-05-22'
        },{
          version: '2.11.0',
          list: [   
            "增加oe模块",           
          ],
          timestamp: '2024-06-22'
        },{
          version: '2.11.1',
          list: [   
            "oe模块中‘条件’，修改为只在所在行的第一个单元格内显示",           
            "子项目上增加oe模块的入口链接",
            "修复贴入句子时随机为本为空导致的异常问题",
            "修复office excel打开下载模板时弹窗问题",
            "增加下载文本处理配置模板时open题的自动贴入",
            "修复open题获取对应vid的方式,以适配open多下划线的情况"
          ],
          timestamp: '2024-07-1'
        },{
          version: '2.11.2',
          list: [   
            "出示逻辑增加‘下载导入模板’",
            "统计修改点选生成表达式的规则，multi类型‘未选中’对应表达式为‘！=1’",           
            "oe模块增加rawdata中本身自带的句子也进行验重的逻辑",
            "oe模块接入Ai功能,包括Ai模板创建，Ai接口调用",
            "oe模块增加对多语言的支持"

          ],
          timestamp: '2024-07-24'
        },{
          version: '2.11.3',
          list: [   
            "oe模块改变贴入时的逻辑，贴入时只获取oe导入的句子，不在获取ai生成的句子",           
            "oe模块修改Ai生成句子时的数量控制逻辑，不在模板中插入count",
            "oe模块Ai模板增加system/背景,并增加QuestionLabel插入功能",
            "oe模块Ai部分接入claude模型",
            "oe模块Ai生成句子时，去掉去重逻辑，提升运行时的速度",
            "oe模块优化部分UI",
            "oe模块修改文本逻辑执行逻辑",

          ],
          timestamp: '2024-08-01'
        },{
          version: '2.11.4',
          list: [   
            "oe模块增加文本逻辑相关功能",                       

          ],
          timestamp: '2024-08-05'
        },{
          version: '2.11.5',
          list: [   
            "项目管理中，恢复check模块，包括：incomplete，screenout",                       
            "调整kpi中11分题规则问题",
            "关联表增加新旧项目的对应的Pgap显示",
            "修复开放浏览报表宏不显示的问题"
          ],
          timestamp: '2024-08-09'
        },{
          version: '2.11.6',
          list: [               
            "增加反算base表达式中，对in 和!= 的支持"
          ],
          timestamp: '2024-10-11'
        },{
          version: '2.11.7',
          list: [               
            "项目备份数据上传入口，去掉文件后缀的判断",
            "项目数据表增加最后上传人（登录名）和上传时间",
            "项目数据增加针对single grid的p值缩放功能",
            "logic导入增加最后上传人（登录名）和上传时间",
            "logic在主项目增加同步到子项目的功能",
            "系统页面标题显示所在模块的名称",
            "开放浏览对外页面，去掉总配额、当前数量等信息"
          ],
          timestamp: '2024-11-13'
        },{
          version: '2.11.8',
          list: [               
            "有道翻译增加多语言的支持，替换原有的自动识别",
            "oe模块ai处理后的文本增加去空格，以及特殊字符的识别跳过功能",  
            "百度文心大模型更换为ERNIE 4.0 Turbo"          
          ],
          timestamp: '2024-12-07'
        },{
          version: '2.11.9',
          list: [               
            "oe模块‘文本逻辑’部分增加对逻辑或关系的支持"            
          ],
          timestamp: '2024-12-10'
        },{
          version: '2.11.10',
          list: [               
          "oe模块‘文本逻辑’修改为通过是否包含特定字符串来判定",
            "oe模块修复‘文本逻辑’导致的贴入文本不全的问题",            
            "oe模块多语言调用的翻译接口变更为百度翻译",
            "oe模块修复已知的部分问题"
          ],
          timestamp: '2024-12-13'
        },{
          version: '2.12.0',
          list: [               
          "新增远程程序管理模块",            
          ],
          timestamp: '2024-12-16'
        },{
          version: '2.12.1',
          list: [               
            "远程项目状态增加默认状态值'无状态'",
          "oe模块多语言部分增加特殊字符的去除",            
          ],
          timestamp: '2024-12-18'
        },{
          version: '2.12.2',
          list: [               
            "reportal模块增加‘默认’项的设置",
            "oldtonew模块百度相似度接口增加对特定错误码的处理，以及空文本的判断",
          "增加保密协议页面",            
          ],
          timestamp: '2024-12-25'
        },{
          version: '2.12.3',
          list: [                           
            "kpi模块增加scene p的批量修改功能（针对子项目）",          
          ],
          timestamp: '2024-12-30'
        },{
          version: '2.12.4',
          list: [                           
            "项目信息增加部分字段",          
            "oe模块增加预贴入运行时的进度显示",
            "oe模块多语言部分修改成多线程模式",
            "oe模块多语言部分增加特殊字符的识别跳过功能，单语言增加特殊字符的去除功能",
          ],
          timestamp: '2025-01-04'
        },{
          version: '2.12.5',
          list: [                           
            "修复kpi-scenep修复时部分未修改问题",          
            "oe模块增加针对中文的检测",
            "oe模块修改特殊字符识别规则",            
          ],
          timestamp: '2025-01-09'
        },{
          version: '2.12.6',
          list: [                           
            "修复远程目录没有携带项目编号的问题",
            "oe模块新增谷歌翻译",          
            "oe模块新增chatgpt和deepseek大模型",                               
          ],
          timestamp: '2025-01-15'
        },{
          version: '2.12.7',
          list: [                           
            "新增项目置顶功能",
            "修复删除项目时异常提示问题",
            "diversity规则变更",                      
            "info-scene修改写入规则，从左侧空单元格开始写入",
            "oldtonew转scene，增加根据p值来设定单元格颜色",
            "oldtonew转p，增加根据样本数来设定p值",
            "修改前端部分ui的文字",
          ],
          timestamp: '2025-01-20'
        },{
          version: '2.13.0',
          list: [                           
            "oe模块新增调用外部api时的重试功能",
            "oe模块新增贴入记录下载功能",
            "oe模块openai调用时线程数单独设置为8",                      
            "oe模块新增对rawdata未完成记录的特殊处理",                      
            "‘deversity’更改为‘diversity’",                      
            "PM操作权限变更，包括数据下载、删除等",                   
            "kpi-pv新增上传rawdata功能",       
            "kpi-关联表变更表头及新增下载功能",       
            "kpi报表变量排序规则变更为依据datamap排序",                                  
          ],
          timestamp: '2025-02-10'
        }


      ]
    };
  },

}
</script>
<style scoped>
#content {
  background-color: white;
  width: 900px;
  text-align: left;
  padding-top: 50px;
  padding-left: 100px;
}

.vers {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.list {
  margin-top: 15px;
  margin-left: 20px;
  font-size: 16px;
  color: #333;
}
::v-deep  .el-timeline-item__timestamp.is-bottom {
    margin-top: 20px;
    margin-left:20px;
    margin-bottom:30px;
}
</style>