<template>
  <div id="content">
    <el-container>
      <el-aside width="130px" style="overflow: hidden!important;">
        <div id="logo">
          <img src="@/assets/logo.png" alt="" width="80%">
        </div>
        <div id="nav">
          <ul>
            <li v-if="perm.padd">
              <router-link to="/padd" active-class="router-link-active"><i class="el-icon-document-add"></i> 创建项目
              </router-link>
            </li>
            <li>
              <router-link to="/plist" active-class="router-link-active"><i class="el-icon-document"></i> 项目管理
              </router-link>
            </li>
            <!-- <li v-if="perm.oldtonew">
              <router-link to="/oldtonew" active-class="router-link-active"><i class="el-icon-menu"></i> oldtonew
              </router-link>
            </li> -->
            <!-- <li v-if="perm.infotomap">
              <router-link to="/infotomap" active-class="router-link-active"><i class="el-icon-menu"></i> infotomap
              </router-link>
            </li> -->
            <li v-if="perm.dt">
              <router-link to="/dt" active-class="router-link-active"><i class="el-icon-menu"></i> dt
              </router-link>
            </li>
            <li v-if="perm.ao">
              <router-link to="/ao" active-class="router-link-active"><i class="el-icon-notebook-2"></i> ao
              </router-link>
            </li>
            <li v-if="perm.sms">
              <router-link to="/sms" active-class="router-link-active"><i class="el-icon-message"></i> sms
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/transform" active-class="router-link-active"><i class="el-icon-menu"></i> 表格转换
              </router-link>
            </li> -->
            <li v-if="perm.comdata">
              <router-link to="/comdata" active-class="router-link-active"><i class="el-icon-folder"></i> 通用数据
              </router-link>
            </li>
            <li>
            <router-link to="/pvreverse" active-class="router-link-active"><i class="el-icon-edit-outline"></i> 反算
              </router-link>
            </li>
           <!-- <li>
              <router-link to="/xxz" active-class="router-link-active"><i class="el-icon-s-custom"></i> Xxz
              </router-link>
            </li>  -->
            <li>
              <router-link to="/xieyi" active-class="router-link-active"><i class="el-icon-lock"></i> 法律信息
              </router-link>
            </li>
            <li v-if="perm.set">
              <router-link to="/set" active-class="router-link-active"><i class="el-icon-s-custom"></i> 设置
              </router-link>
            </li>
            <li>
              <router-link to="/message" active-class="router-link-active"><i class="el-icon-s-comment"></i> 系统消息<el-badge :value="mescount" :hidden="meshid" :max="99" class="item"></el-badge>
              </router-link>
            </li>
            <li v-if="perm.user">
              <router-link to="/user" active-class="router-link-active"><i class="el-icon-s-custom"></i> 用户管理
              </router-link>
            </li>

          </ul>
        </div>
        <div id="version">
          <router-link to="/changelog">版本：2.13.0
          </router-link>
        </div>

      </el-aside>
      <el-container>
        <el-header>
          <p class="login" v-if="nickname">hi, {{ nickname }}<span class="levels">{{ levels }}</span> <el-button
              size="mini" round style="margin:0 10px" @click="exit">
              注销</el-button>
          </p>

          <p>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ weizhi }}</el-breadcrumb-item>
            </el-breadcrumb>
          </p>


        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>

    </el-container>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Head from './components/PageHead'

export default {
  data() {
    return {
      weizhi: '首页',
      nickname: '',
      levels: '',
      mescount:0,
      meshid:true,
      perm: {
        projectadd: false,
        oldtonew: false,
        infotomap: false,
        dt: false,
        ao: false,
        sms: false,
        common: false,
        set: false,
        user: false,

      }
    }
  },
  name: 'PageIndex',
  components: {
    // Head
  },
  methods: {
    exit() {
      localStorage.removeItem('token')
      localStorage.removeItem('permission')
      localStorage.removeItem('nickname')
      localStorage.removeItem('name')
      localStorage.removeItem('levels')
      localStorage.setItem("currentWeizhi", "欢迎您的访问")
      this.$router.push("/login");
    },
    getmessage(){
      var api = this.COMMON.httpUrl + "messageCount";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.mescount=rs.data
          if(this.mescount>0){
            this.meshid=false
          }
        }
      });
    }
  },
  created() {
    this.getmessage()
    const permission = localStorage.getItem('permission')
    var perm = JSON.parse(permission)
    this.perm = perm.menu
    this.nickname = localStorage.getItem('nickname')
    this.levels = localStorage.getItem('levels')
    this.weizhi = localStorage.getItem("currentWeizhi")

  },
  watch: {
    $route(newValue) {
      //console.log(newValue,oldValue)      
      switch (newValue.name) {
        case "index":
          this.weizhi = "欢迎您的访问"
          break
        case "padd":
          this.weizhi = "创建项目"
          break
        case "plist":
          this.weizhi = "项目管理"
          break
          case "pchild":
          this.weizhi = "子项目管理"
          break
        case "oldtonew":
          this.weizhi = "oldtonew"
          break
        case "infotomap":
          this.weizhi = "infotomap"
          break
        case "oeindex":
          this.weizhi = "oe"
          break
        case "dt":
          this.weizhi = "dt"
          break
        case "ao":
          this.weizhi = "ao"
          break
        case "sms":
          this.weizhi = "sms"
          break
        case "transform":
          this.weizhi = "表格转换"
          break
        case "pdata":
          this.weizhi = "项目数据"
          break
        case "user":
          this.weizhi = "用户管理"
          break
        case "set":
          this.weizhi = "账号设置"
          break
        case "upload":
          this.weizhi = "数据上传"
          break
        case "pedit":
          this.weizhi = "项目修改"
          break
        case "comdata":
          this.weizhi = "通用数据"
          break
        case "reportlist":
          this.weizhi = "kpi管理"
          break
        case "info":
          this.weizhi = "info"
          break
        case "errword":
          this.weizhi = "错别字"
          break
          case "pvreverse":
          this.weizhi = "反算"  
          break
          case "logic":
          this.weizhi = "logic"  
          break
          case "message":
          this.weizhi = "系统消息"
          break
          case "quotatab":
          this.weizhi = "配额表"
          break
          case "quotarun":
          this.weizhi = "撞数"
          break          
          case "changelog":
          this.weizhi = "更新日志"
          break
          case "remoteset":
          this.weizhi = "远程管理"
          break
          case "remotelog":
          this.weizhi = "远程日志"          
          break
        default:
        // this.weizhi = localStorage.getItem("currentWeizhi")
        // alert(this.weizhi)
        // alert(localStorage.getItem("currentWeizhi"))

      }
      localStorage.setItem("currentWeizhi", this.weizhi)
    }
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

.login {
  float: right;
}

#content {
  height: 100%;
  background-color: #E9EEF3;
}


.el-container {
  height: 100%;

}

.el-aside {
  height: 100%;
  /* background-color: #f18536; */
  background-color: #fff;
  color: #fff;
  text-align: center;
  /* background-image: url(@/assets/b3.jpg); */

}

#logo {
  height: 100px;
  line-height: 100px;
  font-weight: 800;
  text-align: center;
  color: white;
  border-bottom: 0px solid white;
}



#nav {
  margin-top: 20px;
  height: 200px;
  border: 0px solid red;

}

#nav ul {
  list-style: none;
}

#nav li {
  padding: 0;
  margin: 0;
  text-align: left;
  height: 60px;
  line-height: 60px;
  width: 100%;

  border-bottom: 1px solid #E9EEF3;
  /* background-color: #233a68; */
}

#nav li:hover {
  background-color: #E9EEF3;
}

#nav li a {
  padding-left: 10px;
  display: block;
  width: 100%;
  height: 100%;
  color: #333;
  text-decoration: none;
}

.router-link-active {
  background-color: #E9EEF3;

}

.el-header {
  font-size: 13px;
  color: #F56C6C;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px !important;
  line-height: 50px;
  background-color: white;
  padding-left: 50px !important;
}

.el-breadcrumb {
  height: 50px important;
  line-height: 50px !important;
}

.el-main {
  margin-top: 5px;
  margin-left: 10px;
  /* height: 100%; */
  background-color: white !important;
  color: #333;
  padding-left: 50px !important;
  /* padding-top: 50px !important; */

}

.levels {
  margin-left: 12px;
  color: #c8d0de
}


#version {
  margin-top: 430px;
  height: 100px;
  border: 0px solid red;
  font-size: 12px;
  text-align: left;
  padding-left:15px;
}

#version a {
  color: #333;
  background-color: white;
  text-decoration: none;
}
#version a:hover {

  color: #F56C6C;
  text-decoration: underline;
}

</style>
